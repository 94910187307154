export default function Preloader() {
  return (
    <div className="preloader">
      <div className="flex items-center justify-center gap-2 p-4">
        <div className="w-2 h-2 rounded-full bg-gray-500 animate-[bounce_1s_infinite]"></div>
        <div className="w-2 h-2 rounded-full bg-gray-500 animate-[bounce_1s_infinite_0.2s]"></div>
        <div className="w-2 h-2 rounded-full bg-gray-500 animate-[bounce_1s_infinite_0.4s]"></div>
      </div>
    </div>
  );
}
