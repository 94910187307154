import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './globals.css';
import reportWebVitals from './reportWebVitals';

const chatBot = document.getElementById('chatBot') as HTMLElement;
const root = ReactDOM.createRoot(
  chatBot
);

const botName: string = chatBot.getAttribute('data-botname') || '' as string;
const botDescription: string = chatBot.getAttribute('data-botdescription') || '' as string;
const botNode: string = chatBot.getAttribute('data-botnode') || '' as string;
const botImage: string = chatBot.getAttribute('data-image') || '' as string;

root.render(
  <React.StrictMode>
    <App botName={botName} botDescription={botDescription} botNode={botNode} botImage={botImage} />
  </React.StrictMode>
);

reportWebVitals();
