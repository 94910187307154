import { useState, useEffect } from "react";
import ChatFrame from "./components/chat-frame";
import { Button } from "./components/ui/button";
import { MessageCircle } from "lucide-react";

export default function App(params: { botName: string, botDescription: string, botNode: string, botImage: string }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = (status: boolean) => {
    setIsOpen(!isOpen);
    if (status) {
      const domain = window.location.hostname;
      localStorage.setItem(`bot-${domain}`, 'true');
    } else {
      const domain = window.location.hostname;
      localStorage.removeItem(`bot-${domain}`);
    }
  }

  useEffect(() => {
    const domain = window.location.hostname;
    const botIsOpen = localStorage.getItem(`bot-${domain}`);
    if (botIsOpen === 'true') {
      setIsOpen(true);
    }
  }, []);

  return (
    <div className="fixed right-0 bottom-0 w-full ps-10 lg:ps-0 flex flex-col items-end me-5 mb-5">
      {isOpen && <ChatFrame {...params} />}
      <Button className="rounded-full w-14 h-14" onClick={() => toggleChat(isOpen ? false : true)}>
        <MessageCircle />
      </Button>
    </div>
  );
}
